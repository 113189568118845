import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../../../shared/auth/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { Title } from '@angular/platform-browser';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})

export class NewPasswordComponent implements OnInit {
  title = 'Parceiro Fast | Esqueceu sua senha';
  hide = true;
  newPasswordForm: FormGroup;
  isAuthenticating : boolean = false;
  isInvalid : boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loginService: LoginService,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.newPasswordForm = this.formBuilder.group({
      userName: ['',  [Validators.required, Validators.email] ],
    });
  }

  get f() {
    return this.newPasswordForm.controls;
  }

  newPassword(){
    this.isAuthenticating = true;
    console.log('Novo Login enviado');

    setTimeout(()=>{
      if(this.newPasswordForm.valid) {
        var userName = this.newPasswordForm.get('userName').value;
        this.loginService.forgetPassword(userName).subscribe(
          (resp: ResponseI) => {
            this.errorMessageService.openDialog({
              message: 'Código temporário',
              messageCode: 'Um código de acesso temporário (token) foi enviado para o e-mail cadastrado em nossa base.'
            });
            this.newPasswordForm.reset({},{ onlySelf: false, emitEvent: false} );
            console.log(resp);
            this.router.navigateByUrl('forgot-password', { state: { username: userName} })
          },
          (error: ResponseI) => {
            console.log(error.error);
            this.errorMessageService.openDialog({
              message: error.error.message,
              messageCode: error.error.messageCode
            });
            this.isAuthenticating = false;
            this.isInvalid = true;
          }
        );
      }
    }, 1500)
  }

}



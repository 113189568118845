import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-two-step',
  templateUrl: './two-step.component.html',
  styleUrls: ['./two-step.component.scss']
})
export class TwoStepComponent implements OnInit {
  title = 'Parceiro Fast | Definir autenticação';
  hide = true;
  isAuthenticating : boolean = false;
  isInvalid : boolean = false;
  numberUser: string = '(**) ***** - 1234';

  resp; 
  secret: string = '';
  token: string = '';
  userName: string = '';
  Authorization: string = 'Basic U2VsbGVyTWFuYWdlbWVudDpTJjExZXJNNG5hOGVtZW50';
  status: string = '';
  session: string = '';
  
  constructor(
    private router: Router,
    private loginService: LoginService,
    private titleService: Title

  ) { 
    this.resp = this.router.getCurrentNavigation().extras.state.resp;
    this.userName = this.router.getCurrentNavigation().extras.state.userName;
    this.token = this.resp.key;
    if(this.router.getCurrentNavigation().extras.state.status){
      this.status = this.router.getCurrentNavigation().extras.state.status;
    }
    if(this.router.getCurrentNavigation().extras.state.session) {
      this.session = this.router.getCurrentNavigation().extras.state.session;
    }

  }
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }



  GoToTwoStepLogin() {
    console.log('GoToTwoStepLogin');
  }

  geradorSecretQrCode() {
    this.loginService.generatSecretQrCode(this.Authorization, this.token, this.session).subscribe(
      (success: any) => {
        console.log(success)
          this.secret = success.secret; 
          this.router.navigateByUrl('/two-step-signin', { state: { resp: this.resp,  secret: success.secret, token: this.token, userName: this.userName, status: this.status}, })
      },
      error => {
        console.log(error);
      }
    )
  }

}

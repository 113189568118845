import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../../../shared/auth/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { PasswordValidation } from './../shared/password.validation';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';

import { SellerPortalHosts, environment  } from './../../../../environments/environment';
import { MenuNavigationService } from 'src/app/services/menu-navigation.service';
import { StorageService } from 'src/app/services/storage.service';
import { Title } from '@angular/platform-browser';

interface ResponseI {
  error: {
    code: number;
    message: string;
    key?: string;
    status?: string;
    messageCode?: string;
    warning?: boolean;
  }
}

interface ResponseSuccessI {
  message: string;
  key?: string;
  status?: string;
  totpstatus?: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  title = 'Parceiro Fast | Trocar Senha';
  hide = true;
  hideTwo = true;
  changePasswordForm: FormGroup;
  passwordType: string;
  isAuthenticating: boolean = false;
  isInvalid: boolean = false;
  pwdPattern = "^(?=.*[A-Z])(?=.*[!@#%?_<>)({}´`|\/,.^~$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$";

  email: string = '';
  password: string = '';
  profile: string = 'null';
  Authorization: string = 'Basic U2VsbGVyTWFuYWdlbWVudDpTJjExZXJNNG5hOGVtZW50';


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loginService: LoginService,
    private errorMessageService: ErrorMessageService,
    private menuNavigationService: MenuNavigationService,
    private storageService: StorageService,
    private titleService: Title
  ) {
    this.email = this.router.getCurrentNavigation().extras.state.username;
    this.password = this.router.getCurrentNavigation().extras.state.password;
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.changePasswordForm = this.formBuilder.group({
      userName: [this.email],
      oldpassword: [this.password],
      Password: ['', [Validators.required, Validators.minLength(7), Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(7), Validators.pattern(this.pwdPattern)]]
    },
      {
        validators: PasswordValidation.MatchPassword,
      },
    );
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  newPassword() {
    this.isAuthenticating = true;

    setTimeout(() => {
      if (this.changePasswordForm.valid) {
        const username = this.changePasswordForm.get('userName').value;

        this.isAuthenticating = false;

        this.loginService.newPasswordRequired(this.changePasswordForm.value).subscribe(
          (resp: ResponseSuccessI) => {
            if(resp.totpstatus === 'false'){
              this.authService.login(resp);
              this.profile = this.storageService.storage.getItem(`profile`);
              this.verificaPerfilUsuarioBemVindo(this.profile);
            }
            
            else if(resp.totpstatus === 'create'){
              this.router.navigateByUrl('/two-step', { state: { resp: resp, userName: username, status: 'bem-vindo'}, })
            }

            else if(resp.totpstatus === 'true'){
              this.router.navigateByUrl('/two-step-signin', { state: { resp: resp, userName: username, status: 'bem-vindo'}, })
            }
            // this.authService.login(resp);
            // this.profile = this.storageService.storage.getItem(`profile`);
            // this.verificaPerfilUsuario(this.profile);
            // console.log('redirecionado para home');
            // this.errorMessageService.openDialog({
            //   message: 'Senha criada com sucesso',
            //   messageCode: 'Sua senha definitiva foi criada com sucesso e está valendo a partir do seu próximo acesso.'
            // });
          },
          (error: ResponseI) => {
            console.log(error.error);
            this.errorMessageService.openDialog({
              message: error.error.message,
              messageCode: error.error.messageCode
            });
          }
        );
      }

    }, 1500)
  }

  verificaPerfilUsuarioBemVindo(profile: string) {
    var dataArray = profile;
    var isAdmin = dataArray.indexOf("fastshop") > -1;
    if (isAdmin === true) {
      this.menuNavigationService.isLoggedIn = true;
      this.router.navigate(['bem-vindo']);
    } else {
      this.menuNavigationService.isLoggedIn = true;
      this.router.navigate(['bem-vindo']);
    }
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/auth/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { MenuNavigationService } from 'src/app/services/menu-navigation.service';

import { SellerPortalHosts, environment  } from './../../../../environments/environment';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { Title } from '@angular/platform-browser';


interface ResponseI {
  error: {
    code: number;
    message: string;
    key?: string;
    status?: string;
    messageCode?: string;
    warning?: boolean;
  }
}

interface ResponseSuccessI {
  message: string;
  key?: string;
  status?: string;
  totpstatus?: string;
  session?: string;
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  title = 'Parceiro Fast';
  hide = true;
  loginForm: FormGroup;
  passwordType: string;
  isAuthenticating: boolean = false;
  isInvalid: boolean = false;

  linkFastShopSSO: string = '';
  code: string = null;

  pwdPattern = "^(?=.*[A-Z])(?=.*[!@:#%?_;<>)({}´`|\/,.^~$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$";
  profile: string = 'null';

  @Output() enviarUsername = new EventEmitter<String>();
  static enviandoUsername = new EventEmitter<String>();

  Authorization: string = 'Basic U2VsbGVyTWFuYWdlbWVudDpTJjExZXJNNG5hOGVtZW50';
  username: string = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loginService: LoginService,
    private storageService: StorageService,
    private menuNavigationService: MenuNavigationService,
    private route: ActivatedRoute,
    private errorMessageService: ErrorMessageService,
    private titleService: Title
  ) { 
    this.linkFastShopSSO = environment.linkFastShopSSO;
  }


  ngOnInit(): void {
    this.titleService.setTitle(this.title);
      this.route.queryParams.subscribe(params => {
        this.code = params['code'];
      })
    if(this.code !== undefined) {
     this.loginFastShopSSO(this.code);
    }
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(this.pwdPattern)]]
    });
  }


  get f() {
    return this.loginForm.controls;
  }

  verificaPerfilUsuario(profile: string) {
    var dataArray = profile;
    var isAdmin = dataArray.indexOf("fastshop") > -1;
    if (isAdmin === true) {
      this.menuNavigationService.isLoggedIn = true;
      this.router.navigate(['home/painel']).then(() => {
        setTimeout(() => {
          console.log("IFRAME");
          console.log(document.querySelector('iframe'));
          
          this.menuNavigationService.navigateToRoute(
            document.querySelector('iframe'),
            SellerPortalHosts.SELLER,
            { data: 'home/painel', action: 'routing',force:true }
          );
        }, 2000);
      })
    } else {
      this.menuNavigationService.isLoggedIn = true;
      this.router.navigate(['home/empresa']).then(() => {
        setTimeout(() => {
          this.menuNavigationService.navigateToRoute(
            document.querySelector('iframe'),
            SellerPortalHosts.SELLER,
            { data: 'home/empresa', action: 'routing' , force:true }
          );
        }, 2000)
      })
    }
  }

  loginFastShopSSO (code: string) {
    this.isAuthenticating = true;
    this.loginService.signinFastshopSSO(code).subscribe(
      (resp: ResponseSuccessI) => {
        this.isAuthenticating = false;
        if(resp.totpstatus === 'false'){
          this.authService.login(resp);
          this.profile = this.storageService.storage.getItem(`profile`);
          this.verificaPerfilUsuario(this.profile);
        }
        else if(resp.totpstatus === 'create'){
          var session = resp.session;
          this.router.navigateByUrl('/two-step', { state: { resp: resp, session: session, userName: this.username}, })
        }
        else if(resp.totpstatus === 'true'){
          this.router.navigateByUrl('/two-step-signin', { state: { resp: resp, session: session,  userName: this.username}, })
        }
        // else {
        // this.authService.login(resp);
        // this.profile = this.storageService.storage.getItem(`profile`);
        // this.verificaPerfilUsuario(this.profile);
        // this.isAuthenticating = false;
        // }
      },
      error => {
        this.errorMessageService.openDialog({
          message: 'Erro ao efetuar o login',
          messageCode: 'Não foi possível realizar seu login, tente novamente mais tarde.'
        });
        console.log(error);
        this.isAuthenticating = false;
      }
    )
  }


  LogIn() {
    const username = this.loginForm.get('userName').value;
    const password = this.loginForm.get('Password').value;
    this.isAuthenticating = true;

    setTimeout(() => {
      if (this.loginForm.valid) {
        this.loginService.signIn(username, password).subscribe(
          (resp: ResponseSuccessI) => {
            // this.loginForm.reset({}, { onlySelf: false, emitEvent: false });
            this.isAuthenticating = false;
            if (resp.key === 'NEW_PASSWORD_REQUIRED') {
              this.router.navigateByUrl('/change-password', { state: { username: username, password: password }, })
            } 

            else if(resp.totpstatus === 'false'){
              this.authService.login(resp);
              this.profile = this.storageService.storage.getItem(`profile`);
              this.verificaPerfilUsuario(this.profile);
            }
            
            else if(resp.totpstatus === 'create'){
              this.router.navigateByUrl('/two-step', { state: { resp: resp, userName: username}, })
            }

            else if(resp.totpstatus === 'true'){
              this.router.navigateByUrl('/two-step-signin', { state: { resp: resp, userName: username}, })
            }

            // else {
            //   this.loginService.verifiedStatus(this.Authorization, username).subscribe(
            //     success => {
                  
            //       console.log('consulta status',success)
            //       if(success === false){
            //         this.authService.login(resp);
            //         this.profile = this.storageService.storage.getItem(`profile`);
            //         this.verificaPerfilUsuario(this.profile);
            //       }
                  
            //       else if(success === 'create'){
            //         this.router.navigateByUrl('/two-step', { state: { resp: resp}, })
            //       }
            //       else if(success === true){
            //         this.router.navigateByUrl('/two-step-signin', { state: { resp: resp}, })
            //       }
            //     },
            //     error => {
            //       console.log('error na consulta de status', error);
            //       if(error.status === 200){
            //         this.router.navigateByUrl('/two-step', { state: { resp: resp}, })
            //       }
            //     }
            //   )
            // }

            // else {
            //   this.authService.login(resp);
            //   this.profile = this.storageService.storage.getItem(`profile`);
            //   this.verificaPerfilUsuario(this.profile);
            // }
          },
          (error: ResponseI) => {
            this.isInvalid = true;
            this.isAuthenticating = false;
            console.log(error.error);
          }
        );
      }
    })
  }

  LoginFastShop() {
    console.log('Funcionalidade ainda não implementada.');
    //this.router.navigateByUrl('linkFastShopSSO');
  }

}

import { AbstractControl } from '@angular/forms';
export class PasswordValidation {
  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('Password').value;
    const confirmPassword = AC.get('confirmPassword').value;
    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors({ MatchPassword: true });
    } else if (!!password.length && !!confirmPassword.length) {
      AC.get('confirmPassword').setErrors(null);
      return null;
    }
  }
}

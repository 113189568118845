import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginModel } from './../models/login.model';
import { StorageService } from './storage.service';

export interface LoginDataI {
  oldpassword: string;
  userName: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  private headers = new HttpHeaders({
    'content-type': 'application/json',
    'accept': 'application/json',
  });

  enviarUsername = new EventEmitter<any>();

  ipAddress;
  activeIpAddress;
  constructor(
    private http: HttpClient, 
    private router: Router,
    private storage: StorageService) { 
      this.activeIpAddress = this.getIP();  
    }


  forgetPassword(userName: String): Observable<any> {
    const headerSettings: { [name: string]: string | any } = {};
    headerSettings['userName'] = userName;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http.post(
      `${environment.urlApigee}/${environment.uri}/login/forgotPassword`,
      {},
      { headers: newHeader }
    )
    .pipe(
      map(response => {
        return response;
        console.log(this.headers);
      }));
  }

  signIn(userName: String, Password: String): Observable<any> {
    const headerSettings: { [name: string]: string | any } = {};
    headerSettings['userName'] = userName;
    headerSettings['Password'] = Password;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http
      .post(`${environment.urlApigee}/${environment.uri}/login`,
        {},
        { headers: newHeader })
      .pipe(
        map(user => {
          // login bem-sucedido se houver um token jwt na resposta

          if (user) {
            // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        })
      );
  }

  signInWithCode(token: String, userCode: String): Observable<any> {
    const headerSettings: { [name: string]: string | any } = {};
    headerSettings['token'] = token;
    headerSettings['userCode'] = userCode;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http
      .post(`${environment.urlApigee}/${environment.uri}/login/totpcode`,
        {},
        { headers: newHeader })
      .pipe(
        map(user => {
          // login bem-sucedido se houver um token jwt na resposta

          if (user) {
            // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        })
      );
  }


  signinFastshopSSO(code: string) {
    const headerSettings: { [name: string]: string | any } = {};
    headerSettings['accesscode'] = code;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http
      .post(`${environment.urlApigee}/${environment.uri}/login/accesscode`,
        {},
        { headers: newHeader })
      .pipe(
        map(user => {

          if (user) {
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        })
      );
  }

  confirmNewPassword(login: LoginModel): Observable<any> {
    const headerSettings: { [name: string]: string | any } = {};
    headerSettings['userName'] = login.userName;
    headerSettings['Password'] = login.Password;
    headerSettings['ConformationCode'] = login.ConformationCode;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http
      .post(`${environment.urlApigee}/${environment.uri}/login/confirmForgotPassword`,
        {},
        { headers: newHeader })
      .pipe(
        map(user => {
          // login bem-sucedido se houver um token jwt na resposta
          if (user) {
            // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        })
      );
  }


  newPasswordRequired(loginForm: LoginModel): Observable<any> {
    const headerSettings: { [name: string]: string | any } = {};
    headerSettings['userName'] = loginForm.userName;
    headerSettings['Password'] = loginForm.Password;
    headerSettings['oldpassword'] = loginForm.oldpassword;
    const newHeader = new HttpHeaders(headerSettings);
    return this.http
      .post(`${environment.urlApigee}/${environment.uri}/login/newPswdRequired`,
        {},
        { headers: newHeader })
      .pipe(
        map(user => {
          // login bem-sucedido se houver um token jwt na resposta
          if (user) {
            // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        })
      );
  }

  generatSecretQrCode(Authorization: string, token: string, session?: string) {
    const headerSettings: { [name: string]: string | any } = {};
    headerSettings['authorization'] = Authorization;
    headerSettings['token'] = token;
    headerSettings['session'] = session;
    const newHeader = new HttpHeaders(headerSettings);
  return this.http.post(`${environment.urlApigee}/${environment.uri}/token/secretqrcode`,
    {},
    { headers: newHeader })
    .pipe(
      map(data => {
        return data;
      })
    );
}



    verifiedStatus(Authorization: string, userName: string) {
      const headerSettings: { [name: string]: string | any } = {};
      headerSettings['Authorization'] = Authorization;
      headerSettings['userName'] = userName;
      const newHeader = new HttpHeaders(headerSettings);
    return this.http.get(`${environment.urlApigee}/${environment.uri}/userTOTP/status`,
      { headers: newHeader })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  changeStatusTOTP(Authorization: string, userName: string, status: string) {
    const headerSettings: { [name: string]: string | any } = {};
    headerSettings['authorization'] = Authorization;
    headerSettings['userName'] = userName;
    const newHeader = new HttpHeaders(headerSettings);
  return this.http.post(`${environment.urlApigee}/${environment.uri}/userTOTP/${status}`,
    {},
    { headers: newHeader })
    .pipe(
      map(data => {
        return data;
      })
    );
}

  public getIpAdreess(): Observable<any> {
    return this.http.get<any>(`https://api.ipify.org/?format=json`,
      { headers: this.headers })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getIP()  
  {  
    this.getIpAdreess().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  }  

saveSigninLog() {
  if(this.ipAddress === undefined) {
    this.ipAddress = 'Nao foi possivel identificar o IP';
  }
  var sellerId = this.storage.storage.getItem('sellerId');
  var token = this.storage.storage.getItem('key');
  const headerSettings: { [name: string]: string | any } = {};
    headerSettings['ipOrigin'] = this.ipAddress;
    headerSettings['token'] = token;
    headerSettings['account-api-version'] = 'v1';
    const newHeader = new HttpHeaders(headerSettings);
return this.http.post(`${environment.urlApigee}/sellercenter/v0/${environment.marketplaceId}/sellers/${sellerId}/accounts/${sellerId}/log/login`,
  {},
  { headers: newHeader })
  .pipe(
    map(data => {
      return data;
    })
  );
}


  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
    });
  }

}
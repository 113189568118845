import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../material/material.module';

import { HeaderComponent } from './header/header.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { LoginModule } from '../pages/login/login.module';
import { AppRoutingModule } from '../app-routing.module';

@NgModule({
  declarations: [
    HeaderComponent,
    LeftMenuComponent,
    LayoutComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule { }

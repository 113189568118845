import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from "../../environments/environment";
import { MenuNavigationService } from '../services/menu-navigation.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public isMobile: boolean;
  public isSideMenuOpen: boolean = false;
  public isProduction: boolean = false;
  @ViewChild('sidenav') sidenav;

  constructor(private deviceService: DeviceDetectorService, public menuNavigationService: MenuNavigationService) { }

  ngOnInit(): void {

    this.isProduction = environment.production;
    this.isMobile = this.deviceService.isMobile();
    if (this.isMobile) {
      this.isSideMenuOpen = false;
    }
    else {
      this.isSideMenuOpen = true;
    }
  }

  ngAfterViewInit() {
    document.querySelector('.mat-drawer-inner-container').classList.add('styled-scrollbar');
  }

  toggleMenu(e: boolean) {
    this.isSideMenuOpen = e;
  }

}

import { Injectable } from '@angular/core';
import { StorageService, UserLoggedI } from '../../services/storage.service';
import jtw_decode from 'jwt-decode';
import { LoginService } from 'src/app/services/login.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  profile: string = 'null';

  constructor(
    private storageService: StorageService,
    private loginService: LoginService
  ) { }

  login(key: UserLoggedI) {
    var token = key.key;
    const tokenData = jtw_decode(token);
    if(tokenData['cognito:username']){
      this.storageService.storage.setItem('userName', tokenData['cognito:username']);
    } else {
      this.storageService.storage.setItem('userName', tokenData.username);
    }
    this.storageService.setCurrentUserSession(key);
    this.storageService.storage.setItem('sellerId', key.locationSeller.sellerId);
    this.storageService.storage.setItem('key', key.key);
    this.storageService.storage.setItem('isLoggedIn', 'true');
    this.verificaPerfilUser(tokenData['cognito:groups']);
    this.storageService.storage.setItem('profile', this.profile);
    setTimeout(()=> {
      this.loginService.saveSigninLog().subscribe(
        success => {console.log('log salvo', success)},
        error => {console.log('erro ao salvar log de login', error)}
      );
    }, 3000)
  }

  verificaPerfilUser(cognitoGroup: any){
    for (let i = 0; i < cognitoGroup.length; i++) {
      var dataArray = cognitoGroup[i];
      var isAdmin = dataArray.indexOf("seller.") > -1 || dataArray.indexOf("fastshop.") > -1;
      if(isAdmin) {
        this.profile = cognitoGroup[i];
        console.log(this.profile);
      }
    }
  }

  getToken() {
    return window.localStorage.getItem('key');
  }

  getUserName() {
    return window.localStorage.getItem('userName');
  }

  removeToken(key: UserLoggedI) {
    window.localStorage.removeItem('key');
  }

  setUser(data: UserLoggedI) {
    this.storageService.setCurrentUserSession(data);
  }

  logoutAndRemoveStorage() {
    this.storageService.storage.clear();
    window.location.href = '';
    //return this.router.navigate(['']);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Modules
import { Router, RouterModule } from '@angular/router';
import { MaterialModule } from '../../material/material.module';
import { MessageErrorModule } from 'src/app/shared/components/message-error/message-error.module';
//import { AppRoutingModule } from 'src/app/app-routing.module';

//Components
import { SigninComponent } from './signin/signin.component';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { ChangePasswordComponent  } from './change-password/change-password.component'
import { NewPasswordComponent } from './new-password/new-password.component'
import { BemVindoComponent } from './bem-vindo/bem-vindo.component';
import { TwoStepSigninComponent } from './two-step-signin/two-step-signin.component';
import { TwoStepComponent } from './two-step/two-step.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


@NgModule({
    declarations: [
        SigninComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        NewPasswordComponent,
        BemVindoComponent,
        TwoStepSigninComponent,
        TwoStepComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        //FormsModule,
        //AppRoutingModule,
        MessageErrorModule,
        RouterModule,
        NgxQRCodeModule

        
    ],
    exports: [
        LoginComponent
    ]
})

export class LoginModule { }

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(
    private http: HttpClient,
    private storage: StorageService) { }

  public get(): Observable<any> {
    return this.http.get<any>(`${environment.urlApigee}/sellercenter/v0/${environment.marketplaceId}/sellers`,
      { headers: this.getNewHeaders() })
      .pipe(
        map(response => {
            return response;
          })
      )
  }

  getNewHeaders() {
    return new HttpHeaders({
      'content-type': 'application/json',
      'accept': 'application/json',
      'seller-api-version': 'v1',
      token: this.storage.storage.getItem('key'),
    });
  }
}

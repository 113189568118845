import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { PasswordValidation } from '../shared/password.validation';
import { LoginModel } from './../../../models/login.model';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  title = 'Parceiro Fast | Nova Senha';
  hide = true;
  hideTwo = true;
  forgotForm: FormGroup;
  isAuthenticating: boolean = false;
  isInvalid: boolean = false;
  isInvalidTwo: boolean = false;
  pwdPattern = "^(?=.*[A-Z])(?=.*[!@#%?_<>)({}´`|\/,.^~$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$";
  email;


  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private titleService: Title
  ) {
    this.email = this.router.getCurrentNavigation().extras.state.username;
    }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.forgotForm = this.formBuilder.group({
      userName: [this.email, [Validators.required, Validators.email]],
      ConformationCode: ['', [Validators.required, Validators.maxLength(6)]],
      Password: ['', [Validators.required, Validators.minLength(7), Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(7), Validators.pattern(this.pwdPattern)]],
    },
      {
        validators: PasswordValidation.MatchPassword,
      },
    );

    this.f.userName.disable();
  }

  get f() {
    return this.forgotForm.controls;
  }

  confirmNewPassword() {
    this.isAuthenticating = true;
    this.isInvalid = false;
    this.isInvalidTwo = false;
    console.log('Redefinição de senha com codigo enviado');
    setTimeout(()=>{
      if(this.forgotForm.valid) {
        const forgetValue = this.forgotForm.getRawValue() as LoginModel;
        this.loginService.confirmNewPassword(forgetValue).subscribe(
          (resp: ResponseI) => {
            this.errorMessageService.openDialog({
              message: 'Nova senha redefinida',
              messageCode: 'Sua nova senha foi definida com sucesso e já pode ser usada em seu próximo acesso.'
            });
            this.router.navigate(['']);
            console.log(resp);
          },
          (error: ResponseI) => {
            console.log(error.error);
            if (error.error.message === 'Codigo de confirmação expirado!') {
              this.isAuthenticating = false;
              this.isInvalidTwo = true;
            } else {
              this.isAuthenticating = false;
              this.isInvalid = true;
            }
          }
        );
        this.isAuthenticating = false;
      }
    }, 1500)
  }

}

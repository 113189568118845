import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { BemVindoComponent } from './pages/login/bem-vindo/bem-vindo.component';
import { ChangePasswordComponent } from './pages/login/change-password/change-password.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { NewPasswordComponent } from './pages/login/new-password/new-password.component';
import { SigninComponent } from './pages/login/signin/signin.component';
import { TwoStepSigninComponent } from './pages/login/two-step-signin/two-step-signin.component';
import { TwoStepComponent } from './pages/login/two-step/two-step.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: { title: 'Parceiro Fast | Home' },
    children: [
      {
        path: '',
        component: SigninComponent,
        pathMatch: 'full',
        data: { title: 'Parceiro Fast | Home' },
      },
      {
        path: 'new-password',
        component: NewPasswordComponent,
        data: { title: 'Parceiro Fast | Esqueceu sua senha' },
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: { title: 'Parceiro Fast | Trocar Senha' },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'Parceiro Fast | Nova Senha' },
      },
      {
        path: 'two-step',
        component: TwoStepComponent,
        data: { title: 'Parceiro Fast | Duplo fator' },
      },
      {
        path: 'two-step-signin',
        component: TwoStepSigninComponent,
        data: { title: 'Parceiro Fast | Duplo fator' },
      },
    ],
  },
  {
    path: 'bem-vindo',
    component: BemVindoComponent,
    data: { title: 'Parceiro Fast | Bem Vindo' }
  },
  {
    path: 'home', component: LayoutComponent, 
    data: { title: 'Parceiro Fast | Home' },
  },
  {
    path: '**', redirectTo:'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuNavigationService } from 'src/app/services/menu-navigation.service';
import { SellerPortalHosts } from './../../../../environments/environment';

@Component({
  selector: 'app-bem-vindo',
  templateUrl: './bem-vindo.component.html',
  styleUrls: ['./bem-vindo.component.scss']
})
export class BemVindoComponent implements OnInit {

  constructor(
    private router: Router,
    private menuNavigationService: MenuNavigationService
  ) { }

  ngOnInit(): void {
  }

  goToHome() {
      this.router.navigate(['home/empresa']).then(() => {
        setTimeout(() => {
          this.menuNavigationService.navigateToRoute(
            document.querySelector('iframe'),
            SellerPortalHosts.SELLER,
            { data: 'home/empresa', action: 'routing' , force:true }
          );
        }, 2000)
      })
  }
}

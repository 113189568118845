import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GroupsService } from 'src/app/services/groups.service';
import { MenuNavigationService } from 'src/app/services/menu-navigation.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from './../../shared/auth/auth.service';

import { SellerPortalHosts  } from './../../../environments/environment';


export interface UserLoggedI {
  message?: string;
  key?: string;
  status?: string;
  locationSeller?: LocationSeller;
}
export interface LocationSeller {
  marketplaceId: string;
  sellerId: string;
  accountId: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('selectGroupName') selectGroupName;

  public isMobile: boolean;
  public isMenuOpened: boolean;
  @Output() toggleMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input() fecharMenuLateral;
  
  userName: string = 'Fast Admin';
  profile: string = 'null';
  groups;
  isAdminFastshop: boolean = false;
  public filteredList5;
  companyName: string = 'undefined';
  allCompanyName; 
  selected = '';

  constructor(
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private storageService: StorageService,
    private groupsService: GroupsService,
    private router: Router,
    private menuNavigationService: MenuNavigationService
    ) {
      this.allCompanyName = this.storageService.getUserSession().locationSeller.sellerId;
      this.companyName = this.storageService.storage.getItem(`sellerId`);
      this.selected = this.companyName;
      this.userName = this.authService.getUserName();
      this.profile = this.storageService.storage.getItem(`profile`);
      var isAdmin = this.profile.indexOf("fastshop") > -1 ;
      if(isAdmin === true) {
      this.isAdminFastshop = true;
      this.groupsService.get().subscribe(
        data => {
          this.groups = data;
          this.groups = data;
          this.groups.sort(function (a,b) {
            return (a.groupName > b.groupName) ? 1 : ((b.groupName > a.groupName) ? -1 : 0);
          })
          if(this.selected === 'undefined') {
            this.selected = this.groups[0].groupName;
            this.storageService.storage.setItem('sellerId', this.selected);
          }
          this.filteredList5 = this.groups.slice();
        }
      );
      } else {
        this.isAdminFastshop = false;
        this.storageService.storage.setItem(`sellerId`, this.allCompanyName[0]);
        this.groups = this.allCompanyName;
        this.groups.sort(function (a,b) {
          return (a.groupName > b.groupName) ? 1 : ((b.groupName > a.groupName) ? -1 : 0);
        })
        this.filteredList5 = this.groups.slice();
      }
     }

  ngOnInit(): void {
    this.selected = this.storageService.storage.getItem(`sellerId`);
    this.isMobile = this.deviceService.isMobile();
  }

  filtraLista () {
    if(this.filteredList5.length === 0) {
      this.filteredList5 = this.groups;
    }
  }

    @HostListener('window:message', ['$event'])
    onmessage(message) {
      if(message.data.action === 'changeSellerId') {
        this.storageService.storage.setItem('sellerId', message.data.data.message);
        this.selected = this.storageService.storage.getItem(`sellerId`);
      }
      if (message.data.action === 'changeURL' || message.data.operation === 'changeroute') {
        if(this.isMobile === true) {
          this.toggleMenu();
        }
      }
      else {
        console.log(message);
      }
    }

  setSellerID(event: Event) {
    window.location.reload();

    const iframe = document.querySelector('iframe');
    this.storageService.storage.setItem('sellerId', this.selectGroupName.value);
    this.menuNavigationService.setLocalData(iframe);

    this.menuNavigationService.navigateToRoute(
      iframe,
      SellerPortalHosts.SELLER,
      { data: 'home/empresa', action: 'routing' , force : true , name : "Empresa"},
    );

    return;
  }

  exitToApp() {
    this.authService.logoutAndRemoveStorage();
    this.menuNavigationService.isLoggedIn = false;

  }

  toggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;
    this.toggleMenuEvent.emit(this.isMenuOpened);
  }

}

/** Example file/folder data. */
import { SellerPortalHosts  } from './../../../environments/environment';

// export const SellerPortalHosts = {
//   CADASTRO: 'https://catalog-dev.fastshop.com.br/mpc-ui/',
//   SELLER: 'https://seller-management-webapp-develop.ingress.fastshopdev.com/home',
//   SELLER_QA: 'https://seller-management-webapp-qa.ingress.fastshopdev.com/',
//   // SELLER: 'http://localhost:4300/home',
// }

export const files = [
  {
    name: 'Admin',
    children: [
      // { name: 'Busca por seller', route: '',host: SellerPortalHosts.SELLER },
      { name: 'Associação de atributos', route: 'associacao-de-atributos', host: SellerPortalHosts.CADASTRO },
      { name: 'Associação de hierarquias', route: 'associacao-de-hierarquias', host: SellerPortalHosts.CADASTRO },
      { name: 'Gestão de atributos', route: 'gestao-de-atributos', host: SellerPortalHosts.CADASTRO },
      { name: 'Gestão de hierarquias', route: 'gestao-de-hierarquias', host: SellerPortalHosts.CADASTRO },
      { name: 'Equipe FastShop', route: 'home/equipe-fastshop',host: SellerPortalHosts.SELLER },
      { name: 'Painel Sellers', route: 'home/painel',host: SellerPortalHosts.SELLER },
      { name: 'Pré-cadastro de Sellers', route: 'home/pre-cadastro', host: SellerPortalHosts.SELLER },
    ]
  },
  {
    name: 'Cadastro',
    children: [
      { name: 'Contatos', route: 'home/contatos', host: SellerPortalHosts.SELLER },
      { name: 'Contrato e Documentos', route: 'home/contrato', host: SellerPortalHosts.SELLER },
      { name: 'Empresa', route: 'home/empresa', host: SellerPortalHosts.SELLER },
      { name: 'Integração', route: 'home/integracao', host: SellerPortalHosts.SELLER },
      { name: 'Webstore', route: 'home/webstore', host: SellerPortalHosts.SELLER },
    ]
  },
  {
    name: 'Produtos',
    children: [
      { name: 'Painel de Produtos', route: 'painel-de-produtos', host: SellerPortalHosts.CADASTRO },
      { name: 'Criação de Planilhas', route: 'criacao-planilhas', host: SellerPortalHosts.CADASTRO },
      { name: 'Upload e publicação', route: 'publicacao-de-planilhas', host: SellerPortalHosts.CADASTRO },
    ]
  },
  { name: 'Usuários', route: 'home/usuarios', host: SellerPortalHosts.SELLER }
];

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorMessageService } from 'src/app/shared/modal/error-message/error-message.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LoginService } from 'src/app/services/login.service';
import { MenuNavigationService } from 'src/app/services/menu-navigation.service';

import { SellerPortalHosts } from './../../../../environments/environment';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Title } from '@angular/platform-browser';
interface ResponseSuccessI {
  message: string;
  key?: string;
  status?: string;
}

@Component({
  selector: 'app-two-step-signin',
  templateUrl: './two-step-signin.component.html',
  styleUrls: ['./two-step-signin.component.scss']
})
export class TwoStepSigninComponent implements OnInit {
  title = 'Parceiro Fast | Validação de acesso';
  twoStepForm: FormGroup;
  hide = true;
  isAuthenticating: boolean = false;
  isInvalid: boolean = false;

  profile: string = 'null';

  //qr code 
  secret: string = '';
  token: string = '';
  resp;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "https://www.techiediaries.com/";
  startQr = 'otpauth://totp/';
  nomeQr = 'FastShop'
  endQr = '?secret='
  CodeQr = 'L2Z2WLY3ORDLOMMFB3HZ6HL4C4CK6F6GHVWVLZO6C3BYP6DYWPRQ';
  urlQrCode;
  isFirstSignIn: boolean = false;
  // 'otpauth://totp/{nome_apresentado_qrcode}?secret=L2Z2WLY3ORDLOMMFB3HZ6HL4C4CK6F6GHVWVLZO6C3BYP6DYWPRQ'

  Authorization: string = 'Basic U2VsbGVyTWFuYWdlbWVudDpTJjExZXJNNG5hOGVtZW50';
  userName: string = '';
  status: string = '';

  @ViewChild('campo1') campo1: ElementRef;
  @ViewChild('campo2') campo2: ElementRef;
  @ViewChild('campo3') campo3: ElementRef;
  @ViewChild('campo4') campo4: ElementRef;
  @ViewChild('campo5') campo5: ElementRef;
  @ViewChild('campo6') campo6: ElementRef;


  constructor(
    private formBuilder: FormBuilder,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private loginService: LoginService,
    private menuNavigationService: MenuNavigationService,
    private authService: AuthService,
    private storageService: StorageService,
    private titleService: Title
  ) {
    if (this.router.getCurrentNavigation().extras.state.resp) {
      this.resp = this.router.getCurrentNavigation().extras.state.resp;
      this.token = this.resp.key;
      this.isFirstSignIn = false;
    }
    if (this.router.getCurrentNavigation().extras.state.secret) {
      this.secret = this.router.getCurrentNavigation().extras.state.secret;
      this.token = this.router.getCurrentNavigation().extras.state.token;
      this.resp = this.router.getCurrentNavigation().extras.state.resp;
      this.isFirstSignIn = true;
    }
    if (this.router.getCurrentNavigation().extras.state.status) {
      this.status = this.router.getCurrentNavigation().extras.state.status;
    }

    this.userName = this.router.getCurrentNavigation().extras.state.userName;

    this.urlQrCode = this.startQr + this.nomeQr + this.endQr + this.secret;
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.twoStepForm = this.formBuilder.group({
      numberOne: ['', [Validators.required]],
      numberTwo: ['', [Validators.required]],
      numberThree: ['', [Validators.required]],
      numberFor: ['', [Validators.required]],
      numberFive: ['', [Validators.required]],
      numberSix: ['', [Validators.required]],
    });
  }

  get f() {
    return this.twoStepForm.controls;
  }

  changeValue(event: any, campo: string) {
    if (event.data !== null) {
      switch (campo) {
        case 'campo1':
          this.campo2.nativeElement.focus();
          break;
        case 'campo2':
          this.campo3.nativeElement.focus();
          break;
        case 'campo3':
          this.campo4.nativeElement.focus();
          break;
        case 'campo4':
          this.campo5.nativeElement.focus();
          break;
        case 'campo5':
          this.campo6.nativeElement.focus();
          break;
        case 'campo6':
          console.log('');
          break;
        default:
          console.log('default');
      }
    }
  }

  changeStatus() {
    if (this.userName === '') {
      this.userName = this.storageService.storage.getItem('userName');
    }
    var status = 'TRUE'
    this.loginService.changeStatusTOTP(this.Authorization, this.userName, status).subscribe(
      success => {
        console.log('changeStatus success')
      },
      error => {
        console.log('changeStatus error')
      }
    )
  }

  verificaPerfilUsuarioBemVindo(profile: string) {
    var dataArray = profile;
    var isAdmin = dataArray.indexOf("fastshop") > -1;
    if (isAdmin === true) {
      this.menuNavigationService.isLoggedIn = true;
      this.router.navigate(['bem-vindo']);
    } else {
      this.menuNavigationService.isLoggedIn = true;
      this.router.navigate(['bem-vindo']);
    }
  }

  verificaPerfilUsuario(profile: string) {
    var dataArray = profile;
    var isAdmin = dataArray.indexOf("fastshop") > -1;
    if (isAdmin === true) {
      this.menuNavigationService.isLoggedIn = true;
      this.router.navigate(['home/painel']).then(() => {
        setTimeout(() => {
          this.menuNavigationService.navigateToRoute(
            document.querySelector('iframe'),
            SellerPortalHosts.SELLER,
            { data: 'home/painel', action: 'routing', force: true }
          );
        }, 2000);
      })
    } else {
      this.menuNavigationService.isLoggedIn = true;
      this.router.navigate(['home/empresa']).then(() => {
        setTimeout(() => {
          this.menuNavigationService.navigateToRoute(
            document.querySelector('iframe'),
            SellerPortalHosts.SELLER,
            { data: 'home/empresa', action: 'routing', force: true }
          );
        }, 2000)
      })
    }
  }

  LogIn() {
    this.isAuthenticating = true;

    setTimeout(() => {
      if (this.twoStepForm.valid) {
        var code = this.twoStepForm.get('numberOne').value + this.twoStepForm.get('numberTwo').value + this.twoStepForm.get('numberThree').value +
          this.twoStepForm.get('numberFor').value + this.twoStepForm.get('numberFive').value + this.twoStepForm.get('numberSix').value;
        this.loginService.signInWithCode(this.token, code).subscribe(
          (resp: ResponseSuccessI) => {
            this.twoStepForm.reset({}, { onlySelf: false, emitEvent: false });
            this.isAuthenticating = false;
            this.authService.login(this.resp);
            this.profile = this.storageService.storage.getItem(`profile`);
            this.changeStatus();
            if (this.status === 'bem-vindo') {
              this.verificaPerfilUsuarioBemVindo(this.profile);
              this.errorMessageService.openDialog({
                message: 'Senha criada com sucesso',
                messageCode: 'Sua senha definitiva foi criada com sucesso e está valendo a partir do seu próximo acesso.'
              });
            } else {
              this.verificaPerfilUsuario(this.profile);
            }

          },
          (error: any) => {
            this.isInvalid = true;
            this.isAuthenticating = false;
            this.errorMessageService.openDialog({
              message: 'Erro ao efetuar o login',
              messageCode: error.error.message
            })
            .afterClosed().subscribe(() => {
              this.twoStepForm.reset();
              this.campo1.nativeElement.focus();
            });
          }
        );
      }
    })
  }

}

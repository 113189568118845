export const environment = {
  production: true,
  urlApigee: 'https://apigw.fastshop.com.br',
  uri: 'sellercenter/v0/iam/fastid/fastshop',
  marketplaceId: 'sellercenter-master',
  linkFastShopSSO: 'https://sellercenter-master.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=fastshopadibm&redirect_uri=https://parceirofast.fastshop.com.br/&response_type=CODE&client_id=748j240gkcu7e1cifoe0rhv063&scope=aws.cognito.signin.user.admin%20email%20openid%20profile',
  sellerManagementApiURL : 'https://seller-management-api-develop.devqa.fastshopdigital.com/sellercenter'
};

export const SellerPortalHosts = {
  SELLERCENTER: 'https://apigw.fastshop.com.br/sellercenter/v0',
  CADASTRO: 'https://apigw.fastshop.com.br/mpc/v0/shop-mpc',
  SELLER: 'https://seller-management.fastshop.com.br/home',
  FINANCEIRO : "https://apigw.fastshop.com.br/financial/v0/financial-manager",
  FMS: "https://apigw.fastshop.com.br/financial/v0/financial-shipping"
}

export const SellerPortalHostsApigee = {
  SELLERCENTER: 'https://apigw.fastshop.com.br/sellercenter/v0',
  CADASTRO: 'https://api.fastshop.com.br/shop-mpc',
  SELLER: 'https://seller-management.fastshop.com.br/home',
  FINANCEIRO : "https://api.fastshop.com.br/v1/financial-manager",
  FMS: "https://api.fastshop.com.br/fms-api"
}

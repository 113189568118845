import { Injectable } from '@angular/core';

export interface UserLoggedI {
  message?: string;
  key?: string;
  status?: string;
  locationSeller?: LocationSeller;
}
export interface LocationSeller {
  marketplaceId: string;
  sellerId: string;
  accountId: string;
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storage: Storage = window.localStorage;

  getUserSession(): UserLoggedI {
    return JSON.parse(this.storage.getItem(`currentUser`));
  }


  setCurrentUserSession(parameters: UserLoggedI) {
    this.storage.setItem('currentUser', JSON.stringify(parameters));
  }
  setCurrentTokenDataSession(parameters: UserLoggedI) {
    this.storage.setItem('tokenData', JSON.stringify(parameters));
  }

  removeCurrentUserSession() {
    this.storage.removeItem('currentUser');
  }

  removeCurrentTokenDataSession() {
    this.storage.removeItem('tokenData');
  }

  isLoggedIn(): boolean {
    return !!JSON.parse(this.storage.getItem(`isLoggedIn`));
  }
}
